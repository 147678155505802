@import '../helper/mixins';

body {
  font-optical-sizing: auto;
  font-style: normal;

  @media screen and (max-width: $smScreen) {
    font-size: 1rem;
  }
  &.disable-scroll {
    padding-right: var(--scrollbar-width, 0);
    overflow: hidden;

    #header, #main-navigation-wrapper {
      padding-right: var(--scrollbar-width, 0);
    }
    .sf-toolbar > div {
      right: var(--scrollbar-width, 0);
    }
  }
}

#page {
  position: relative;
  width: 100%;
  overflow: hidden;

  body:not(.editmode) &.empty-first-view {
    padding-top: var(--header-height);
  }
}

#header {
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--header-height);
  background-color: transparent;
  z-index: 198;
  transform: translateZ(0);
  @include transition-effect(background-color);

  @media screen and (max-width: $smScreen) {
    height: 70px;

    .logo {
      width: auto;
      height: 50px;
    }
  }
  #page.empty-first-view &,
  &.opac {
    background-color: $white;
    box-shadow: 1px 3px 12px rgba($black, .15);
  }
  &.header-up {
    transform: translateY(0);
    animation: 250ms ease-in-out 0s 1 normal forwards running moveUp;
  }
  &.header-down {
    animation: 250ms ease-in-out 0s 1 normal forwards running moveDown;

    &.initial-load {
      animation-duration: 0ms;
    }

    &:not(.initial-load) {
      transform: translateY(-200px);
    }
  }
  > .container, > .container > .row {
    height: 100%; // fix for IE 11
  }
  .contact {
    font-size: 1.5rem;
    line-height: 1;

    a {
      color: $primary;
      text-decoration: none;
      @include transition-effect(color);

      + a {
        margin-left: 1.25rem;
      }
      &:hover {
        color: $secondary;
      }
    }
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200px);
  }
}

@keyframes moveDown {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

#logo {

}

#footer {
  background-color: rgba($primary, .1);
  color: $primary;

  .contact {
    border: 2px dashed rgba($secondary, .5);
    font-size: 1.375rem;
    font-weight: $font-weight-normal;

    a {
      text-decoration: none;
    }
  }
}

#back-to-top {
  color: $white;
  border-radius: 50%;
  background-color: rgba($secondary, .75);
  border: 1px dashed $white;
  outline: 5px solid rgba($secondary, .75);
  bottom: 1.875rem;
  left: 1.875rem;
  text-decoration: none !important;
  opacity: 0;
  z-index: 200;
  @include transition-effect(all);

  &.visible {
    opacity: 1;

    &:hover {
      transform: scale(1.1);
    }
  }
}
