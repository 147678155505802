:root {


  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --color-primary: rgba(180, 149, 121, 1);
  --color-primary-90: rgba(180, 149, 121, .9);
  --color-secondary: #754927;
  --color-white: rgb(255, 255, 255);
  --color-white-50: rgb(255, 255, 255, .5);
  --color-black: rgba(35, 35, 35, 1);
  --color-black-50: rgba(35, 35, 35, .5);
  --color-grey: rgb(51, 51, 51);
  --color-grey-light: rgb(242, 242, 242);
  --color-mandatory: rgb(207, 0, 52);
  --color-wood: rgba(166, 103, 68, 1);
  --color-wood-50: rgba(211, 179, 161, 1);
  --color-pine: rgba(242, 182, 148, 1);
  --color-pine-50: rgba(249, 219, 201, 1);


  --header-height: 90px;
  --logo-height: 70px;
}

//@import 'animon/dist/animon.css';
@import 'aos/dist/aos.css';

// custom variables
$xxsScreen: 424px;
$xsScreen: 575px;
$smScreen: 767px;
$mdScreen: 991px;
$lgScreen: 1199px;
$xlScreen: 1399px;
$xxlScreen: 1599px;

@import 'helper/bootstrap';
@import 'helper/placeholders';
@import 'helper/fonts';


b, strong {
  font-weight: $font-weight-semibold;
}
.tooltip-inner {
  font-weight: $font-weight-light !important;
}

@media screen and (max-width: $xsScreen) {
  .container {
    padding-left: $spacer;
    padding-right: $spacer;
  }
}

@import 'components/layout';
@import 'components/navigation';
@import 'components/first-view';
@import 'components/content';