.content {

  > :last-child {
    margin-bottom: 0 !important;
  }
}

h1, .h1 {
  font-family: $font-family-josefin-sans;
  color: $brown;

  body:not(.editmode) & {
    text-transform: uppercase;
  }
  @media screen and (max-width: $smScreen) {
    font-size: 1rem;
  }
}
h2, .h2 {
  line-height: .95;
  color: $primary;

  @media screen and (max-width: $lgScreen) {
    font-size: 3.125rem; // 50px
  }
  @media screen and (max-width: $smScreen) {
    font-size: 2.5rem; // 40px
  }
}
h3, .h3 {
  color: $primary;

  @media screen and (max-width: $smScreen) {
    font-size: 1.875rem; // 30px
  }
}
h4, .h4 {
  font-family: $font-family-josefin-sans;
  color: $brown;

  body:not(.editmode) & {
    text-transform: uppercase;
  }
  @media screen and (max-width: $smScreen) {
    font-size: 1rem;
  }
}
p + h4 {
  margin-top: $paragraph-margin-bottom;
  margin-bottom: 1rem;
}


.btn {

  @media screen and (max-width: $smScreen) {
    font-size: 1rem;
  }
  &.btn-default {
    border-style: dashed;
    border-color: $secondary;
    padding-bottom: .75rem;

    body:not(.editmode) & {
      text-transform: uppercase;
    }
    &:hover {
      background-color: rgba($secondary, .375);
      color: $primary;
    }
  }
  &.btn-text {
    display: flex;
    align-items: center;
    padding: 0 !important;

    &::after {
      @extend %icomoon;
      content: "\e912";
      margin-left: .5rem;
      @include transition-effect(right);
      position: relative;
      right: 0;
    }
    &:hover {
      &::after {
        right: -.5rem;
      }
    }
  }
}

.content {

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: $paragraph-margin-bottom;

    li {
      position: relative;
      padding-left: 1.75rem;

      &::before {
        @extend %icomoon;
        content: '\e92b';
        position: absolute;
        left: 0;
        top: 3px;
      }
      + li {
        margin-top: .75rem;
      }
    }
  }
}


.teaser-caption-wrapper {
  top: 0;
  left: 0;

  .teaser-caption {
    background-color: var(--color-primary-90);
    border: 2px dashed var(--color-secondary);
    outline: 10px solid var(--color-primary-90);
    color: var(--color-white);

    @media screen and (max-width: $smScreen) {
      outline-width: 7px;
    }
    .h3 {
      line-height: 1;
      color: inherit;
    }
    .btn {
      color: inherit;
      font-weight: $font-weight-light;
    }
  }
}

.blockquote {

  &::before {
    @extend %icomoon;
    content: "\e999";
    position: absolute;
    top: -60%;
    right: 0;
    font-size: 15rem;
    color: rgba($secondary, .1);

    @media screen and (max-width: $smScreen) {
      top: -50%;
      font-size: 10rem;
    }
  }
  span::after {
    content:"\a";
    white-space: pre;
  }
}
.blockquote-footer {
  color: $grey;
}

.content-block-separator {

  hr {
    border-top: 2px dashed $secondary;
  }
}

.tip {
  background-color: rgba($primary, .1);
  border: 2px dashed $secondary;
  outline: 10px solid rgba($primary, .1);
  color: $primary;
}

.content-block-sitemap {
  a {
    text-decoration:  none !important;
  }
}