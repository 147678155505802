#first-view {
  overflow: hidden;

  body:not(.home) & {
    height: 600px;

    @media screen and (max-width: $smScreen) {
      height: auto;
    }
  }
  body.home & {
    max-height: 100vh;
  }
  body:not(.editmode) &::before {
    width: 100%;
    height: 25%;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(var(--color-white) 0%, transparent 100%);
    z-index: 2;
  }
  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    left: -30px;
    right: -30px;
    top: -30px;
    bottom: -30px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: var(--background);
    background-size: cover;
    filter: blur(10px);

   // background: url(" (source: " attr(cite) ") ");
  }


  .first-view-media {
    height: 100%;
    max-height: 100vh;
    z-index: 1;

    figcaption {
      width: 200px;
      height: 200px;
      background-color: var(--color-primary-90);
      bottom: 5rem;
      right: 5rem;
      border-radius: 50%;
      border: 2px dashed var(--color-secondary);
      outline: 10px solid var(--color-primary-90);
      color: var(--color-white);
      font-size: 1.5rem;
      line-height: 1.2;
      font-weight: $font-weight-normal;

      @media screen and (max-width: $mdScreen) {
        font-size: $font-size-base;
        width: 140px;
        height: 140px;
        bottom: 3.5rem;
        right: 3.5rem;
        outline-width: 7px;
      }
      @media screen and (max-width: $mdScreen) {
        bottom: 1.875rem;
        right: 1.875rem;
      }
      @media screen and (max-width: $mdScreen) {
        bottom: $spacer;
        right: $spacer;
      }
    }
    img {
      max-width: none;
      object-fit: cover;
    }
  }
}