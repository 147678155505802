// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: #754a27;
$secondary: #b49479;
$brown: #554d45;
$black: #1f1d1a;
$grey: #8f8e8c;

$body-color: $black;

$font-family-josefin-sans: 'Josefin Sans', serif;
$font-family-alex-brush: 'Alex Brush', serif;

$font-family-sans-serif: $font-family-josefin-sans;
$font-size-base: 1.125rem;
$font-weight-base: 300;

$paragraph-margin-bottom:   1.75rem;

$headings-font-family: $font-family-alex-brush;
$headings-font-weight: 400;

$h1-font-size: $font-size-base;
$h2-font-size: 3.75rem; // 60px
$h3-font-size: 2.5rem; // 40px
$h4-font-size: $font-size-base;
//$h5-font-size:                $font-size-base * 1.25 !default;
//$h6-font-size:                $font-size-base !default;

$input-btn-padding-y: 1rem;
$input-btn-padding-x: 2.5rem;
//$input-btn-font-family:       null !default;
//$input-btn-font-size:         $font-size-base !default;
//$input-btn-line-height:       $line-height-base !default;

//$input-btn-focus-width:         $focus-ring-width !default;
//$input-btn-focus-color-opacity: $focus-ring-opacity !default;
//$input-btn-focus-color:         $focus-ring-color !default;
//$input-btn-focus-blur:          $focus-ring-blur !default;
//$input-btn-focus-box-shadow:    $focus-ring-box-shadow !default;

//$input-btn-padding-y-sm:      .25rem !default;
//$input-btn-padding-x-sm:      .5rem !default;
//$input-btn-font-size-sm:      $font-size-sm !default;

//$input-btn-padding-y-lg:      .5rem !default;
//$input-btn-padding-x-lg:      1rem !default;
//$input-btn-font-size-lg:      $font-size-lg !default;

$input-btn-border-width: 2px;
$border-radius: 0;
$btn-color: $primary;

$tooltip-font-size: $font-size-base;
$tooltip-bg: rgba($secondary, .9);
$tooltip-border-radius: .375rem;
$tooltip-padding-x: .75rem;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

$spacer: 1.5rem;
$spacers: (
  0: 0,
  1: $spacer * .25, // 6px
  2: $spacer * .5, // 12px
  3: $spacer, // 24px
  4: $spacer * 2, // 48px
  5: $spacer * 3, // 72px
  6: $spacer * 4.25 // 102px
);


// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
//@import "bootstrap/scss/tables";
//@import "bootstrap/scss/forms";
//@import "bootstrap/scss/forms/form-select";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
// @import "bootstrap/scss/nav";
//@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/accordion";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
//@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/list-group";
//@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
//@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
//@import "bootstrap/scss/placeholders";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// 8. Add additional custom code here