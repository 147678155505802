
.nav-btn {
  cursor: pointer;
  @include transition-effect(top, 250ms);

  &:hover {

    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
  input {

    &:checked {

      + label {

        .nav-icon {
          background: transparent !important;

          &::before, &::after {
            top: 0;
            width: 100%;
            transition: top ease 250ms, transform ease 250ms 250ms;
          }
          &::before {
            transform: rotate(45deg);
            left: 0;
          }
          &::after {
            transform: rotate(-45deg);
            right: 0;
          }
        }
      }
    }
  }
  label {
    cursor: pointer;
    z-index: 2;
    color: $primary;
    @include transition-effect(color);

    .icon-menu {
      font-size: 2.5rem;
    }
  }
  &:hover {

    label {
      color: $secondary;

    }
  }
}

body.masked::before {
  content: '';
  position: absolute;
  z-index: 199;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($black, .75);
}

#main-navigation-wrapper {
  top: 0;
  right: 0;
  width: 33%;
  height: 100vh;
  background-color: rgba($secondary, .9);
  opacity: 0;
  visibility: hidden;
  overflow-x: scroll;
  z-index: 200;
  @include transition-effect(opacity);

  @media screen and (max-width: $lgScreen) {
    width: 50%;
  }
  @media screen and (max-width: $smScreen) {
    width: 85%;
  }
  &.open {
    visibility: visible;
    opacity: 1;
  }
  #main-navigation {

    .navigation {
      color: $white;


      > li {
        border-bottom: 2px dashed rgba($primary, .5);
        padding-bottom: 1rem;

        + li {
          margin-top: 1rem;

          @media screen and (max-width: $smScreen) {
            margin-top: 1.5rem;
          }
        }
        > a {
          text-transform: uppercase;
          font-weight: $font-weight-normal;
          font-size: $font-size-lg;
          @include transition-effect(all);
          display: block;

          @media screen and (max-width: $smScreen) {
            font-size: $h4-font-size;
          }

        }

        &:hover {

          > a {

            color: $primary;
          }
        }

        &.active {

          > a {
            color: $primary;
          }
        }
      }
      a {
        color: inherit;
        text-decoration: none;
      }
      .sub-navigation {


        > li {
          margin-top: .375rem;

          > a {

            .icon-arrow-right {
              opacity: 0;
              right: 0;
              @include transition-effect(all);
            }
          }
          &:hover {
            > a {
              .icon-arrow-right {
                opacity: 1;
                right: -.5rem;
              }
            }
          }
          &.active {

            > a {
              color: $primary;
              font-weight: $font-weight-normal;
            }
          }
        }
      }
    }
  }
  .opening-hours {
    background-color: transparent;

    span {
      background-color: var(--color-white);
      color: var(--color-primary);
      font-weight: var(--font-weight-medium);
      font-size: $h3-font-size;
      line-height: 1;
    }
  }
}

#close-main-navigation {
  color: $white;
  font-size: $font-size-lg;
  top: 1.875rem;
  right: 1.875rem;
  cursor: pointer;
  @include transition-effect(all);
  border-radius: 50%;
  border: 1px dashed $white;

  &:hover {
    color: $primary;
    border-color: $primary;
  }
}

#footer-navigation {

  ul {
    font-size: 1rem;

    @media screen and (max-width: $smScreen) {
      font-size: .875rem;
    }
    li, a {
      color: #8d8d8d;
    }
    li {

      + li::before {
        content: '|';
        margin: 0 .75rem;

        @media screen and (max-width: $smScreen) {
          margin: 0 .5rem;
        }
        @media screen and (max-width: $xsScreen) {
          content: '';
          margin: 0;
        }
      }
      + li {

        @media screen and (max-width: $xsScreen) {
          margin-top: .375rem;
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
}